// If you add more items here, make sure to update the get-census-data-min-max endpoint in the backend, and update the list of data points there too.
export const dataItems = [
  {
    label: "Population",
    startYear: 2010,
    endYear: 2021,
    value: "populationData",
    icon: "👨‍👩‍👧‍👦",
    description: `Areas with increasing population often signal a higher demand for housing. Investing in areas where people are moving to, rather than from, could offer more security for your investment.`,
  },
  {
    label: "Employment",
    value: "employmentData",
    startYear: 2011,
    endYear: 2020,
    icon: "👨‍👩‍👧‍👦",
    description: `Areas with higher employment rates might indicate a stable economy, which can be attractive to potential tenants.`,
  },
  {
    label: "Median Gross Rent",
    value: "medianGrossRent",
    startYear: 2011,
    endYear: 2020,
    icon: "👨‍👩‍👧‍👦",
    description: `Investors can assess whether the median gross rent aligns with their investment goals and yields. It aids landlords in making informed decisions regarding rent adjustments during lease renewals. A rising median gross rent may indicate increasing demand.`,
  },
  {
    label: "Median Income",
    value: "medianIncome",
    startYear: 2011,
    endYear: 2020,
    icon: "👨‍👩‍👧‍👦",
    description: `Areas with higher median incomes might suggest greater financial stability of tenants or buyers, potentially reducing investment risks. Rising median income might indicate areas experiencing economic growth, potentially offering lucrative investment opportunities.`,
  },
  {
    label: "Number of Units",
    value: "numberOfUnitsData",
    startYear: 2010,
    endYear: 2021,
    icon: "🏠",
    description: `The number of units in a building is a crucial factor in determining the rent. A higher number of units may indicate a larger property size, which could lead to higher rent costs. Conversely, a smaller number of units may suggest a smaller property size, potentially reducing rent costs.`,
  },
  {
    label: "Active Listing",
    value: "activeListeningCountData",
    startYear: 2016,
    endYear: 2023,
    icon: "📈",
    description: `Active Listing is a metric that measures the number of properties that are currently for sale in a given area. It is calculated by taking the total number of properties in a given area and subtracting the number of properties that are currently sold. A higher active listing count may indicate that the area is experiencing a higher demand for properties, potentially leading to higher rent costs.`,
  },
  {
    label: "Median Days on Market",
    value: "medianDaysOnMarketData",
    startYear: 2016,
    endYear: 2023,
    icon: "📈",
    description: `Median Days on Market is a metric that measures the average number of days that a property has been on the market. It is calculated by taking the average of the days on the market for all the properties in a given area. A higher median days on market may indicate that the area is experiencing a higher demand for properties, potentially leading to higher rent costs.`,
  },
  {
    label: "Median Listing Price",
    value: "medianListingPriceData",
    startYear: 2016,
    endYear: 2023,
    icon: "💰",
    description: `Median Listing Price is a metric that measures the average price of a property. It is calculated by taking the average of the prices of all the properties in a given area. A higher median listing price may indicate that the area is experiencing a higher demand for properties, potentially leading to higher rent costs.`,
  },
  {
    label: "New Listing",
    value: "newListingCountData",
    startYear: 2010,
    endYear: 2023,
    description: ``,
  },
  {
    label: "Price Reduced Count",
    value: "priceReducedCountData",
    startYear: 2016,
    endYear: 2023,
    description: ``,
  },
  {
    label: "Pending Listing Count",
    value: "pendingListingCountData",
    startYear: 2016,
    endYear: 2023,
    description: ``,
  },
  {
    label: "Total Listing",
    value: "totalListingCount",
    startYear: 2016,
    endYear: 2023,
    description: ``,
  },
  {
    label: "Cap Rate",
    value: "capData",
    startYear: 2016,
    endYear: 2023,
    description: ``,
  },
];
/*
'Population': 'populationData',
'Employment': 'employmentData',
'Median Price': 'medianListingPriceData',
'Active Listing': 'activeListeningCountData',
'Days on Market': 'medianDaysOnMarketData',
'New Listing': 'newListingCountData',
'Price Reduced Count': 'priceReducedCountData',
'Pending Listing Count': 'pendingListingCountData',
'Total Listing': 'totalListingCount',
'Median Gross Rent': 'medianGrossRent',
'Median Income': 'medianIncome',
'Cap Rate': 'capData'
*/
export const years = [
  2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
];

export function getDataItemByValue(value) {
  return dataItems.find((item) => item.value === value);
}
