import { Button } from "../ui/button";
import { useState, useEffect, useRef, useCallback } from "react";
import { Input } from "@/components/ui/input";
import {
  Sheet,
  SheetTrigger,
  SheetDescription,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Search,
  XIcon,
  LineChartIcon,
  ChevronRightIcon,
  HouseIcon,
} from "lucide-react";
import { twMerge } from "tailwind-merge";
import MapBox from "./MapBox";
import { getDataPointsMinMax, fetchZipcodesList } from "./mapUtils";
import { dataItems, getDataItemByValue } from "./utils";
import DataLineChart from "./DataLineChart";
import AutoCompleteInput from "@/components/CustomUI/AutoCompleteInput";
import { toast } from "react-toastify";
import useFetch from "@/hooks/useFetch.jsx";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";

export default function DataMap() {
  const [isDataPanelOpen, setIsDataPanelOpen] = useState(false);
  const [isDataChartPanelOpen, setIsDataChartPanelOpen] = useState(false);

  const [focusedRegion, setFocusedRegion] = useState({
    zipcode: "",
    lat: null,
    lng: null,
  });

  const { data: regionsList, loading: isRegionsListLoading } =
    useFetch(fetchZipcodesList);

  const [dataFilter, setDataFilter] = useState({
    label: dataItems[0].label,
    dataPoint: dataItems[0].value,
    year: dataItems[0].endYear,
    minVal: 0,
    maxVal: 5,
  });
  const allDataMinMax = useRef({});

  async function getMinMax() {
    const minMaxData = await getDataPointsMinMax();
    if (!minMaxData || !minMaxData.success) return;
    allDataMinMax.current = minMaxData.data;
    return minMaxData.data;
  }

  useEffect(() => {
    (async () => {
      const minMaxData = await getMinMax();
      if (!minMaxData) return;
      const newDataFilter = {
        ...dataFilter,
        minVal: minMaxData?.[dataFilter.dataPoint]?.[dataFilter.year]?.minVal,
        maxVal: minMaxData?.[dataFilter.dataPoint]?.[dataFilter.year]?.maxVal,
      };
      setDataFilter(newDataFilter);
    })();
  }, []);

  const handleGetSuggestions = (val) => {
    if (!regionsList || regionsList.length === 0) return [];
    const suggestions = regionsList
      .filter((reg) => reg.zipcode.startsWith(val))
      .slice(0, 5)
      .map((reg) => reg.zipcode);
    return suggestions;
  };

  return (
    <div className="w-screen h-screen">
      <div className="w-full h-full relative">
        <div
          data-label="Top Bar"
          className="absolute w-full p-4 flex items-center justify-center space-x-2 z-[1]"
        >
          <AutoCompleteInput
            placeholder={"Enter ZIP code..."}
            value={focusedRegion?.zipcode}
            isLoading={isRegionsListLoading}
            onValueChange={(value) =>
              setFocusedRegion((prev) => ({
                ...(prev || {}),
                zipcode: value,
              }))
            }
            getSuggestions={handleGetSuggestions}
            onSuggestionSelected={(suggestion) => {
              const regionIndex = regionsList.findIndex(
                (reg) => reg.zipcode === suggestion
              );
              if (regionIndex === -1) {
                toast.error(
                  "Sorry! We couldn't locate that ZIP code on the map."
                );
                return;
              }
              const region = regionsList[regionIndex];
              setFocusedRegion((prev) => ({
                ...(prev || {}),
                zipcode: region.zipcode,
                lat: region.location.lat,
                lng: region.location.lng,
              }));
            }}
            debounceDelay={250}
          />
          <div className="space-x-2">
            <TooltipProvider>
              <Sheet
                open={isDataChartPanelOpen}
                onOpenChange={setIsDataChartPanelOpen}
              >
                <SheetTrigger>
                  <Tooltip>
                    <TooltipTrigger>
                      <Button
                        size="icon"
                        variant="outline"
                        // className="bg-[#556BD6] hover:bg-[#556BD6]/90 text-white"
                      >
                        <LineChartIcon className="h-5 w-5" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent className="bg-gray-50 text-black">
                      View Trends
                    </TooltipContent>
                  </Tooltip>
                </SheetTrigger>
                <SheetContent side="bottom" className="">
                  <SheetHeader>
                    <SheetTitle>
                      {getDataItemByValue(dataFilter.dataPoint)?.label} -{" "}
                      {focusedRegion?.zipcode}
                    </SheetTitle>
                    <SheetDescription>
                      Showing {getDataItemByValue(dataFilter.dataPoint)?.label}{" "}
                      data for {focusedRegion?.zipcode} zipcode.
                    </SheetDescription>
                    {/* Keep the max height of DataLineChart and container same */}
                    <div className="max-h-[500px]">
                      <DataLineChart
                        className="max-h-[500px]"
                        zipcode={focusedRegion?.zipcode}
                        datapoint={dataFilter.dataPoint}
                      />
                    </div>
                  </SheetHeader>
                </SheetContent>
              </Sheet>
              <Tooltip>
                <TooltipTrigger>
                  <Link to="/buy?page=1">
                    <Button
                      size="icon"
                      variant="outline"
                      // className="bg-[#556BD6] hover:bg-[#556BD6]/90 text-white"
                    >
                      <HouseIcon className="h-5 w-5" />
                    </Button>
                  </Link>
                </TooltipTrigger>
                <TooltipContent className="bg-gray-50 text-black">
                  Go Home
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <div
          data-label="Side Bar"
          className={twMerge(
            "fixed z-20 h-full w-80 bg-background/95 p-4 shadow-lg backdrop-blur transition-transform duration-300",
            isDataPanelOpen ? "" : "-translate-x-full"
          )}
        >
          <Button
            // size="1"
            className={cn(`absolute top-4 -right-10 p-0`, `sm:p-1`)}
            onClick={() => setIsDataPanelOpen(!isDataPanelOpen)}
            // variant="secondary"
            variant="outline"
            size="icon"
          >
            <ChevronRightIcon
              strokeWidth={2}
              className={twMerge(
                "transition-transform duration-700",
                isDataPanelOpen ? "rotate-180" : ""
              )}
            />
          </Button>
          <div className="space-y-6">
            <div>
              <h2 className="mb-2 font-semibold">Select Data</h2>
              <Select
                value={dataFilter.dataPoint}
                onValueChange={(value) => {
                  const thisItem = dataItems.find(
                    (item) => item.value === value
                  );
                  const latestYear = thisItem?.endYear;

                  setDataFilter({
                    ...dataFilter,
                    dataPoint: value,
                    year: latestYear, // Show latest data so we select the latest year
                    label: thisItem?.label,
                    minVal:
                      allDataMinMax?.current?.[value]?.[latestYear]?.minVal ||
                      0,
                    maxVal:
                      allDataMinMax?.current?.[value]?.[latestYear]?.maxVal ||
                      5,
                  });

                  // Close the data panel whenever the data is changed
                  setIsDataPanelOpen(false);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Choose data type" />
                </SelectTrigger>
                <SelectContent>
                  {dataItems.map((item, idx) => (
                    <SelectItem key={idx} value={item.value}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div>
              <h2 className="mb-2 font-semibold">Description</h2>
              <p className="text-sm text-muted-foreground">
                {getDataItemByValue(dataFilter.dataPoint)?.description}
              </p>
            </div>
          </div>
        </div>
        <div
          data-label="Map Container"
          className="w-full h-full bg-gray-100 z-0"
        >
          <MapBox
            dataFilter={dataFilter}
            focusedRegion={focusedRegion}
            onRegionClick={(region) => {
              setFocusedRegion(region);
              setIsDataChartPanelOpen(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}
